
import { DateisValid } from "../../../utils/components/accuracyandlcandrangesshow";

export let printSRFSSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: false,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: false,
  jrpmtable: false,
  readingTableSerialNoColumnShow: false,
};

const parseValue = (value) => {
  value = value || "";
  value = value.replace("_unit_", "");
  value = value.replace("|", " to ");
  value = value.replace("#", " ");
  return value;
};

export const srfDetailsData = (srfDetails) => {
  return {
    leftPanel: {
      keys: ["Address","Job No","GST No", 
        //  "DC Number", "Entry Date",
        "Email","Certificate in Name"
      ],
      values: [
        srfDetails?.newAddressArray?.address ||
          srfDetails?.newAddressArray?.[0]?.address ||
          "",
        srfDetails?.companyData?.gstNumber || "",
        srfDetails?.serviceReqNumber || "",
        // srfDetails?.dcNumber || "",
        // DateisValid(srfDetails?.entryDate),
        srfDetails?.clientArray?.emailId || "",
        srfDetails?.billingData?.billingName || "",
      ],
    },
    rightPanel: {
      keys: [
        "Delivery Challan No & Date",
        "Date",
        "Customer Name",
        // "DC Date",
        // "Calibration to be Completed on",
        "Phone Number",
        "Address"
      ],
      values: [
        srfDetails?.dcNumber || "",
        DateisValid(srfDetails?.entryDate,"DD-MM-YYYY"),
        srfDetails?.clientArray?.contactPersonName || "",
        // DateisValid(srfDetails?.dcDate),
        // DateisValid(srfDetails?.commitedDate),
        srfDetails?.clientArray?.contact || "",
        srfDetails?.biiingAddress?.address || "",
      ],
    },
  };
};

// Exporting MasterEQPDetails
export const InstrumentDetails = (pushArray) => ({
  heading: "DETAILS OF STANDARD USED FOR CALIBRATION",
  headingFontSize: 9,
  headingBottomSpace: 0,
  headingTopSpace: 0,
  headingAlign: "center",
  spacebetweenHeadingandTable: 5,
  cellPadding: { top: 3, left: 2, bottom: 3, right: 2 },
  tableTextfontSize: 7.2,
  tableValueAlign: "center",
  keys: [
    "Sl.No.",
    "Description of the Equipment",
    "Make",
    "Model",
    "Serial Number",
    "Location",
    "Identification Number",
    "Range",
    "Calibration Points If any",
    "Declared Accuracy / Acceptance Criteria",
    "Requested Due Date",
    "Condition of UUC / Remarks",
  ],
  values: pushArray.map((item, index) => [
    index + 1,
    item.instrumentId || "",
    parseValue(item.make) || "",
    item.model || "",
    item.serialNumber || "",
    item.location || "",
    item.DUCID || "",
    item.ranges || "",
    item.calPoint || "",
    item.accuracy || "NA",
    item.calFrequency || "",
    item.ConOfDuc || "",
    // DateisValid(item.validUpto),
  ]),
});

export const NameAndAddressDetails = (SRFSDetails) => {
  return {
    leftPanel: {
      keys: ["Client"],
      values: [
        SRFSDetails?.companyName || "",
        // SRFSDetails?.newAddressArray?.address ||
        //   SRFSDetails?.newAddressArray?.[0]?.address ||
          "",
      ],
    },
    rightPanel: {
      keys: ["PO No/Quotation Approved Reference", "Date"],
      values: [
        SRFSDetails?.srfsData?.poNumber || "",
        DateisValid(SRFSDetails?.srfsData?.poDate,"DD-MM-YYYY"),
      ],
    },
    cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
    tableTextfontSize: 9,
  };
};

export const headerConfig = {
  headerCALIBRATIONSRFS: true,
  headerCALIBRATIONSRFSgap: 10,
  headerCALIBRATIONSRFSwithoutgap: 7,
  lettterImgHeight: 120,
  headerTableHeight: 15,
  headerandbodygap: 10,
  tableRowfontSizes: 10,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONSRFSxpostion: headerConfig.headerCALIBRATIONSRFS
    ? headerConfig.lettterImgHeight + headerConfig.headerCALIBRATIONSRFSgap
    : headerConfig.lettterImgHeight +
      headerConfig.headerCALIBRATIONSRFSwithoutgap,
  headerCALIBRATIONSRFSxpostionafter:
    headerConfig.lettterImgHeight + headerConfig.headerCALIBRATIONSRFSgap + 10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONSRFS
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  footerimgRemove: false,
  footerimgHeight: 20,
  footertableHeight: 30,
  footerandbodygap: 35,
  endSRFSandimggap: 5,
  stampui: false,
};
let pageHeight = 841;
export const SRFSBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    6,
  // height: 570
};

export const dynamicHeader = (SRFSDetailsConfig) => {
  return {
    leftPanel: {
      keys: ["SRF Number"],
      values: [SRFSDetailsConfig?.serviceReqNumber || ""],
    },
    rightPanel: {
      keys: ["Entry Date"],
      values: [DateisValid(SRFSDetailsConfig?.entryDate,"DD-MM-YYYY")],
    },
    lastPanel: {
      keys: ["Format No."],
      values: ["7.1/R-03 ,Rev:00"],
    },
  };
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 250,
  logoHeight: 200,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

export const firstpageNotes = [
  "Note : If Conformity Statement requested by customer then the specification or standard applied for conformity statement and the decision rule shall be reported on calibration certificate. The decision rule shall be applied as below.",
  "PASS – Error ± expanded uncertainty within limits / specifications",
  "PASS# - Error are within limits/Specifications but overlap when expanded uncertainty is taken into account.",
  "FAIL# - Error exceeds limits/specifications but overlap when expanded uncertainty is taken into account.",
  "FAIL – Error ± expanded uncertainty exceeds limits / specifications."
];

export let lastpagesection = [
  {
    type: "header",
    align: "center",
    content: "TERMS & CONDITIONS",
  },
  {
    type: "header",
    align: "center",
    content: "Subject to change / modification without prior notice",
  },
  {
    type: "table",
    index: true,
    content: [
      "ACPL follows only National / International calibration methods. ACPL assumes that the calibration methods used by the Laboratory and our best measuring capabilities are acceptable to the customer.",
      "Charges are payable 100 % against delivery through cash / cheque in favour of ARCHERCAL PRIVATE LIMITED, MUMBAI.",
      "Equipments and their accessories delivered to ACPL shall be in working condition. Damage if any, shall be recorded in the SRF.",
      "ACPL personnel shall take every possible care in handling and/or use of customer’s equipment. ACPL, however, shall not be liable for any financial damage and/or legally bound for any damage to customer’s equipment during job execution, and which are attributed to the properties specifications of equipment. Liability of ACPL shall be restricted to repair of equipment in the event of damage during period of custody with ACPL. Delivery/collection through courier shall be the responsibility of customer.",
      "Equipment submitted by customer may be returned to customer without executing the job under circumstances or exigencies beyond the control of ACPL. Customer will be briefed by ACPL personnel in such events and provided with appropriate advice.",
      "The customer shall satisfy themselves at the time of accepting delivery of the equipments and reports.",
      "Customer has to make own arrangements for taking delivery of their equipments. Report would be dispatched by courier whenever requested by customer.",
      "As a policy, electronic transmission of results is not permitted. In special cases of urgency, the customer is requested to submit such written request to ACPL. While receiving and approving such request, ACPL shall assume that the customer has assumed total responsibility and risk involved for any deviation in the end result.",
      "Calibration report issued by ACPL is not to be used for any legal purpose and shall not be produced in court of law. For any disputes, decision of Directors of ACPL will be final.",
      "Customer shall collect equipments within 15 days of job execution. If equipments are not collected within reasonable time and/or without satisfactory justification, additional service charge may be levied. Decision of Directors of ACPL shall be final.",
      "Customer shall recognize that a satisfactory calibration report in no way implies that the equipment calibrated is approved by ACPL. No part of calibration report shall be used by customer for promotional or publicity purpose in any way that ACPL may consider misleading.",
      "As a customer friendly practice, a PDCR (probable date of job completion with report) is mentioned in the SRF. Customer may therefore enquire about the job completion only on/after the PDCR quoting SRF number.",
      "ACPL shall make delivery of equipment and related report to customer representative only on production of customer copy of SRF, in absence of which ACPL personnel may refuse to hand over the same.",
      "Equipments submitted for calibration must be accompanied by the following:",
      ".     Details of the Equipments: Model & Serial Number (accessories to be specified separately)",
      ".     Operating manual",
      ".     Previous Calibration Report (wherever possible)",
      "In absence of specific instructions from customer, all calibrations shall be performed in accordance with ACPL Quality System Procedure.",
      "ARCHERCAL PRIVATE LIMITED in neither allowed to make misleading/ unauthorised statement regarding its accreditation nor refer to accreditation in such a way so as to imply that a product calibrated by ACPL, it's process, service, management system or person is approved by NABL."
    ]
  },
];