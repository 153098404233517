// MAster submodule id selection start from 51 in UserMAster
// Commercial submodule id selection start from 111 in UserMAster
export const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Master",
  },
  // Master children from 51 to 62
  {
    id: 51,
    name: "Master EQP",
  },
  {
    id: 52,
    name: "Instrument",
  },
  {
    id: 53,
    name: "Duc Master",
  },
  {
    id: 54,
    name: "Discipline",
  },
  {
    id: 55,
    name: "Remark",
  },
  {
    id: 57,
    name: "Client",
  },
  {
    id: 58,
    name: "Units",
  },
  {
    id: 59,
    name: "Table Template",
  },
  {
    id: 60,
    name: "Datasheet Template",
  },
  {
    id: 61,
    name: "Uncertainty",
  },
  {
    id: 62,
    name: "Settings",
  },
  {
    id: 63,
    name: "Assigned Duc",
  },
  {
    id: 3,
    name: "Inward",
  },
  {
    id: 4,
    name: "Datasheets",
  },
  {
    id: 5,
    name: "Certificates",
  },
  {
    id: 7,
    name: "Courier Register",
  },
  {
    id: 8,
    name: "Expenses",
  },
  {
    id: 12,
    name: "Scheduler",
  },
  {
    id: 13,
    name: "External Calibration",
  },
  {
    id: 35,
    name: "Reports",
  },
  {
    id: 151,
    name: "Summary Reports",
  },
  {
    id: 152,
    name: "History Reports",
  },
  {
    id: 153,
    name: "Work Reports",
  },
  {
    id: 154,
    name: "WCR Report",
  },
  {
    id: 155,
    name: "Department Analytics",
  },  
  {
    id: 156,
    name: "Inward Register",
  },
  {
    id: 157,
    name: "Progress Report",
  },
  {
    id: 158,
    name: "SRF_ULR_Register",
  },
  {
    id: 14,
    name: "Commercial",
  },
   // Commercial children from 111 to 117
  {
    id: 111,
    name: "Enquiry",
  },
  {
    id: 112,
    name: "Enquiry Followup",
  },
  {
    id: 113,
    name: "Price List",
  },
  {
    id: 114,
    name: "Quotation",
  },
  {
    id: 115,
    name: "poAcceptance",
  },
  {
    id: 116,
    name: "Invoice",
  },
  {
    id: 117,
    name: "Supplier Invoice",
  },
  {
    id: 17,
    name: "Payment",
  },
   // Paymnet children from 161 to 163
  {
    id: 161,
    name: "Payment History",
  },
  {
    id: 162,
    name: "Account Statement",
  },
  {
    id: 163,
    name: "GST Report",
  },
  {
    id: 21,
    name: "Master In/Out",
  },
   // Master children from 171 to 172
  {
    id: 171,
    name: "In/Out List",
  },
  {
    id: 172,
    name: "In/Out Report",
  },
  {
    id: 25,
    name: "User",
  },
  {
    id: 27,
    name: "Audit",
  },
  {
    id: 28,
    name: "Client Complaints",
  },
  {
    id: 29,
    name: "Client Feedback",
  },
  {
    id: 30,
    name: "Request Form",
  },
  {
    id: 31,
    name: "Document Upload",
  },
  {
    id: 32,
    name: "Help",
  },
  {
    id: 33,
    name: "Attendance",
  },
  {
    id: 34,
    name: "MarkAttendance",
  },
  {
    id: 35,
    name: "Reports",
  },
  {
    id: 36,
    name: "WCR Report",
  },
  {
    id: 37,
    name: "Department Analytics",
  },
  {
    id: 38,
    name: "Lab Scheduler",
  }
];

export const moduleHierarchy = {
  2: { min: 51, max: 63 },   // Master
  14: { min: 111, max: 117 }, // Commercial
  35: { min: 151, max: 158 }, // Reports
  17: { min: 161, max: 163 }, // payments
  21: { min: 171, max: 172 }, // master in/out
};

export const userDesignation = [
  {
    id: 1,
    name: "Calibration Engineer",
  },
  {
    id: 2,
    name: "Senior Engineer",
  },
  {
    id: 3,
    name: "Senior Calibration Engineer",
  },
  {
    id: 4,
    name: "Quality Manager",
  },
  {
    id: 5,
    name: "Technical Manager",
  },
  {
    id: 6,
    name: "Technical Head",
  },
  {
    id: 7,
    name: "Lab Head",
  },
  {
    id: 8,
    name: "Asst. Technical Manager",
  },
  {
    id: 9,
    name: "Chief Executive Director",
  },
  {
    id: 10,
    name: "Junior Accountant",
  },
  {
    id: 11,
    name: "Senior Accountant",
  },
  {
    id: 12,
    name: "Account Head",
  },
  {
    id: 13,
    name: "Lab Incharge",
  },
  {
    id: 14,
    name: "Executive Director-Technical",
  },
  {
    id: 15,
    name: "Testing Engineer",
  },
  {
    id: 16,
    name: "Senior Testing Engineer",
  },
  {
    id: 17,
    name: "Lab Assistance",
  },
  {
    id: 18,
    name: "Technical Assistant ",
  },
  {
    id: 19,
    name: "CEO",
  },
  {
    id: 20,
    name: "Director",
  },
  {
    id: 21,
    name: "Chief Executive Officer",
  },
  {
    id: 22,
    name: "Technical Director",
  },
  {
    id: 23,
    name: "Dy.Technical Manager",
  },
];

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },
  {
    id: 4,
    label: "Branch Admin",
  },
  {
    id: 2,
    label: "Engineer",
  },
  {
    id: 3,
    label: "Client",
  },
  {
    id: 5,
    label: "Lab Co-Ordinator",
  },
  {
    id: 6,
    label: "Account Dept",
  },
];

export const rolesArray = [
  {
    name: "Calibration Authority ",
    id: "1",
  },
  {
    name: "Approved Authority",
    id: "2",
  },
  ,
  {
    name: "Reviewed By",
    id: "3",
  },
  ,
  {
    name: "Signed By",
    id: "4",
  },
  ,
  {
    name: "Witnessed By",
    id: "5",
  },
];

export const headers = [
  { name: "Client" },
  { name: "Address" },
  { name: "Start Date" },
  { name: "End Date" },
];

export const editAccessOptions = [
  {
    id: 0,
    label: "All Access",
  },
  {
    id: 1,
    label: "Edit",
  },
  {
    id: 2,
    label: "Delete",
  },
  {
    id: 3,
    label: "PDF Download",
  },
  {
    id: 4,
    label: "Excel Download",
  },
  {
    id: 5,
    label: "Copy Data",
  },
  {
    id: 6,
    label: "None",
  },
  {
    id: 7,
    label: "Failed Certificate Approval",
  },  
  {
    id: 8,
    label: "Backdate Show",
  },
]
