import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import Logo from "../image/sticker.jpeg";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RefreshIcon from "@mui/icons-material/Refresh";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";
import TranslateIcon from "@mui/icons-material/Translate";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import InfoIcon from "@mui/icons-material/Info";
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import MoreIcon from "@mui/icons-material/More";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Badge,
  Box,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Popover,
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import { NotificationList } from "../notification/Notification";
import MarkAttendanceModal from "../attendance/markAttendanceModal ";
import ResetPassword from "./resetPassword";
import { userDesignation } from "../../constants/masterUserConstants";
import SymbolCopyModal from "./symbolCopyModal";
import { addloginEntryInAudit } from "../login/Login";
import HistoryIcon from "@mui/icons-material/History";
import DataUsageIcon from "@mui/icons-material/DataUsage";

export const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [masterNested, setMasterNested] = React.useState(false);
  const [reportsNested, setReportsNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [MasterInOut, setMasterInOut] = React.useState(false);
  const [CommercialNested, setCommercialNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const [notificationsNo, setNotificationsNo] = React.useState(0);
  const [resposiveAnchor, setResposiveAnchor] = React.useState({
    left: false,
  });
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  // const selectedRouteInUpperCase = selectedRoute
  const [dropDown, setDropDown] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const designation = localStorage.getItem("designationIds")?.split(",") || [];
  let isOffLine = localStorage.getItem("isOffLine") == "true";

  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const handleClickDesignation = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverDesignationClosed = () => {
    setPopoverAnchor(null);
  };

  const handleDesignationSelect = (selectedId) => {
    const selectedDesignation = userDesignation.find(
      (designation) => designation.id === parseInt(selectedId)
    );
    if (selectedDesignation) {
      setSelectedDesignation(selectedDesignation.name);
      localStorage.setItem("designation", selectedDesignation.name);
      handlePopoverDesignationClosed();
    }
  };

  const storage_userName = localStorage.getItem("userName");
  const _UserID = localStorage.getItem("id");
  const storage_userType = localStorage.getItem("type");
  const storage_modules = localStorage
    .getItem("modules")
    ?.split(",")
    .map((e) => e)
    .map((e) => Number(e));

  const handleClickDropDown = (event) => {
    setDropDown(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setDropDown(null);
  };

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = async () => {
    await addloginEntryInAudit(
      "logout",
      _UserID,
      moment().format("YYYY-MM-DD HH:mm:ss")
    );
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("logInUser");
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.clear();
    navigate("/");
    window.location.reload(true);
  };
  let previouspath = sessionStorage.getItem("currentPath");
  const clearSearchFilterSessionStorage = (to) => {
    const shouldConfirmNavigation = [
      "/datasheet/edit",
      "/inward/editInward",
      "/master/masterEQP/editStandard",
      "/master/instrument/createInstrument",
      "/master/instrument/editInstrument",
      "/master/editStaticTable",
    ].some((route) => pathname.startsWith(route));

    if (shouldConfirmNavigation) {
      if (window.confirm("Are you sure you want to quit?")) {
        if (to !== previouspath) {
          sessionStorage.removeItem("searchBy");
          sessionStorage.removeItem("searchKey");
          sessionStorage.removeItem("searched");
          sessionStorage.removeItem("currentPath");
        }
        navigate(to);
      }
    } else {
      if (to !== previouspath) {
        sessionStorage.removeItem("searchBy");
        sessionStorage.removeItem("searchKey");
        sessionStorage.removeItem("searched");
        sessionStorage.removeItem("currentPath");
      }
      navigate(to);
    }
  };

  const [isSymbolModalOpen, setIsSymbolModalOpen] = useState(false);
  const openSymbolModal = () => {
    setIsSymbolModalOpen(true);
  };
  const closeSymbolModal = () => {
    setIsSymbolModalOpen(false);
  };

  const hasAccessToModule = (key) => {
    key = Number(key);
    if (DISABLED_MODULE?.includes(key)) return false;
    if (`${storage_userType}` === "1") return true;
    else if (
      `${storage_userType}` === "2" ||
      `${storage_userType}` == "4" ||
      `${storage_userType}` == "3" ||
      `${storage_userType}` == "5" ||
      `${storage_userType}` == "6"
    ) {
      if (storage_modules?.includes(key)) return true;
      else return false;
    } else {
      if ([1].includes(key)) return true;
      else return false;
    }
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
    crossTab: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open3 = Boolean(anchorEl2);

  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    if (props.rolesInfo.roles && props.rolesInfo.roles?.length > 1)
      setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const nav_modules = [
    // {
    //   type: "item",
    //   component: Link,
    //   path: "/dashboard",
    //   icon: <DashboardIcon />,
    //   name: storage_userType === "3" ? "Home" : "Dashboard",
    //   moduleId: "1",
    // },
    {
      type: "item",
      component: Link,
      icon: <FormatListBulletedIcon />,
      name: "Master",
      moduleId: "2",
      onClick: (e) => setMasterNested(!masterNested),
      extra: masterNested ? <ExpandLess /> : <ExpandMore />,
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    setResposiveAnchor({ ...resposiveAnchor, [anchor]: open });
  };
  const sidebarListItems = () => (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        paddingTop: "0px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {hasAccessToModule("1") && !DISABLED_MODULE?.includes("Dashboard") && (
        <ListItemButton
          onClick={() => clearSearchFilterSessionStorage("/dashboard")}
        >
          <ListItemIcon sx={{ minWidth: "45px" }}>
            <Tooltip
              title={storage_userType === "3" ? "Home" : "Dashboard"}
              arrow
              placement="right"
            >
              <DashboardIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText
              primary={storage_userType === "3" ? "Home" : "Dashboard"}
            />
          ) : (
            <Typography variant="p" component="div" align="left">
              {storage_userType === "3" ? "Home" : "Dashboard"}
            </Typography>
          )}
        </ListItemButton>
      )}

      {nav_modules.map((module) => {
        if (module.type === "item") {
          if (module.component)
            return (
              !isOffLine &&
              hasAccessToModule(module.moduleId) &&
              (module.to ? (
                <ListItemButton
                  component={module.component}
                  to={module.path}
                  onClick={module.onClick}
                  key={module.component}
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title={module.name} arrow placement="right">
                      {module.icon}
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={module.name} />
                  ) : (
                    <ListItemText>
                      <Typography
                        variant="p"
                        component="div"
                        align="left"
                        style={{ fontSize: "14px" }}
                      >
                        {module.name}
                      </Typography>
                    </ListItemText>
                  )}
                  {module.extra || ""}
                </ListItemButton>
              ) : (
                <ListItemButton onClick={module.onClick} key={module.component}>
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title={module.name} arrow placement="right">
                      {module.icon}
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={module.name} />
                  ) : (
                    <ListItemText>
                      <Typography
                        variant="p"
                        component="div"
                        align="left"
                        style={{ fontSize: "14px" }}
                      >
                        {module.name}
                      </Typography>
                    </ListItemText>
                  )}
                  {module.extra || ""}
                </ListItemButton>
              ))
            );
        }
      })}
      {hasAccessToModule(2) && !DISABLED_MODULE?.includes("Master") && (
        <Collapse in={masterNested} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {hasAccessToModule(51) &&
              !DISABLED_MODULE?.includes("Master EQP") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/masterEQP")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Master EQP" arrow placement="right">
                      <BuildIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Master EQP"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Master EQP"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(52) &&
              !DISABLED_MODULE?.includes("Instrument") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/instrument")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Instrument" arrow placement="right">
                      <PrecisionManufacturingIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Instrument"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Instrument"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(53) &&
              !DISABLED_MODULE?.includes("DUC Master") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/ducMasterList")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="DUC Master" arrow placement="right">
                      <AcUnitIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"DUC Master"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"DUC Master"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(63) &&
              !DISABLED_MODULE?.includes("Assigned Duc") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/assignedDucList")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Assigned Duc" arrow placement="right">
                      <AcUnitIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Assigned Duc"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Assigned Duc"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(54) &&
              !DISABLED_MODULE?.includes("Discipline") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/discipline")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Discipline" arrow placement="right">
                      <ArchitectureIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Discipline"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Discipline"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(55) && !DISABLED_MODULE?.includes("Remark") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/master/remark")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Remark" arrow placement="right">
                    <MoreIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Remark"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Remark"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(57) && !DISABLED_MODULE?.includes("Client") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/master/client")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Client" arrow placement="right">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Client"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Client"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(58) && !DISABLED_MODULE?.includes("Units") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/master/unit/unitList")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Units" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Units"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Units"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(59) &&
              !DISABLED_MODULE?.includes("Table Template") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/template")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Table Template" arrow placement="right">
                      <TableChartIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Table Template"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Table Template"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(60) &&
              !DISABLED_MODULE?.includes("Datasheet Template") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/datasheetTemplate")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Datasheet Template" arrow placement="right">
                      <TableChartIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Datasheet Template"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Datasheet Template"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(61) &&
              !DISABLED_MODULE?.includes("Uncertainty") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage(
                      "/master/uncertinity/uncertinityList"
                    )
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Uncertainty" arrow placement="right">
                      <TableChartIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Uncertainty"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Uncertainty"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
            {hasAccessToModule(62) &&
              !DISABLED_MODULE?.includes("Settings") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    clearSearchFilterSessionStorage("/master/setting")
                  }
                >
                  <ListItemIcon sx={{ minWidth: "45px" }}>
                    <Tooltip title="Settings" arrow placement="right">
                      <TableChartIcon />
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={"Settings"} />
                  ) : (
                    <Typography variant="p" component="div" align="left">
                      {"Settings"}
                    </Typography>
                  )}
                </ListItemButton>
              )}
          </List>
        </Collapse>
      )}
      {hasAccessToModule(3) && !DISABLED_MODULE?.includes("Inward") && (
        <List component="div" disablePadding>
          <ListItemButton
            // sx={{ pl: 4 }}
            onClick={() => clearSearchFilterSessionStorage("/inward")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Inward" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>{" "}
            {!isMobile ? (
              <ListItemText primary={"Inward"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Inward"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      )}

      {hasAccessToModule("4") && !DISABLED_MODULE?.includes("Datasheets") && (
        <ListItemButton
          onClick={() => clearSearchFilterSessionStorage("/datasheet")}
          id="editsrf_navbar_datasheet"
        >
          <ListItemIcon sx={{ minWidth: "45px" }}>
            <Tooltip title="Datasheets" arrow placement="right">
              <TableViewIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Datasheets"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Datasheets"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("5") && !DISABLED_MODULE?.includes("Certificates") && (
        <ListItemButton
          onClick={() => clearSearchFilterSessionStorage("/certificate")}
          id="datasheet-editdatasheet_navbar_certificate"
        >
          <ListItemIcon sx={{ minWidth: "45px" }}>
            <Tooltip title="Certificates" arrow placement="right">
              <ArticleIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Certificates"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Certificates"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {!isOffLine &&
        hasAccessToModule("7") &&
        !DISABLED_MODULE?.includes("Courier Register") && (
          <ListItemButton
            onClick={() =>
              clearSearchFilterSessionStorage("/courier/courierList")
            }
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Courier Register" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Courier Register"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Courier Register"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {!isOffLine &&
        hasAccessToModule("8") &&
        !DISABLED_MODULE?.includes("Expenses") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/master/expense")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Expenses" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Expenses"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Expenses"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {!isOffLine &&
        hasAccessToModule("12") &&
        !DISABLED_MODULE?.includes("Scheduler") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/scheduler")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Scheduler" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Scheduler"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Scheduler"}
              </Typography>
            )}
          </ListItemButton>
        )}

{!isOffLine && hasAccessToModule("38") && !DISABLED_MODULE?.includes("Lab Scheduler") && (
        <ListItemButton
        onClick={()=>clearSearchFilterSessionStorage("/labScheduler")}
         >
          <ListItemIcon>
            <Tooltip title="Lab Scheduler" arrow placement="right">
              <ArticleIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Lab Scheduler"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Lab Scheduler"}
            </Typography>
          )}
        </ListItemButton>
      )}

{!isOffLine &&
        hasAccessToModule("13") &&
        !DISABLED_MODULE?.includes("External Calibration") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/externalCalibrationList")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="External Calibration" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"External Calibration"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"External Calibration"}
              </Typography>
            )}
          </ListItemButton>
        )}


{!isOffLine && hasAccessToModule("35") &&
        !DISABLED_MODULE?.includes("Reports") && (
          <ListItemButton
            // component={Link}
            // to="/masterEQPInOutList"
            onClick={(e) => setReportsNested(!reportsNested)}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Reports" arrow placement="right">
                <FormatListBulletedIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Reports"} />
            ) : (
              <ListItemText>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  style={{ fontSize: "14px" }}
                >
                  {"Reports"}
                </Typography>
              </ListItemText>
            )}
            {reportsNested ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      <Collapse in={reportsNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasAccessToModule("151") &&
            !DISABLED_MODULE?.includes("Summary Report") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/summaryReport")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Summary Report" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Summary Report"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Summary Report"}
                  </Typography>
                )}
              </ListItemButton>
            )}
          {hasAccessToModule("152") &&
            !DISABLED_MODULE?.includes("History Card") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => clearSearchFilterSessionStorage("/historyCard")}
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="History Card" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"History Card"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"History Card"}
                  </Typography>
                )}
              </ListItemButton>
            )}
          {hasAccessToModule("153") &&
            !DISABLED_MODULE?.includes("Work Report") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => clearSearchFilterSessionStorage("/workReport")}
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Work Report" arrow placement="right">
                    <ReceiptIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Work Report"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Work Report"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule("154") &&
            !DISABLED_MODULE?.includes("WCR Report") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => clearSearchFilterSessionStorage("/wcrReport")}
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="WCR Report" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"WCR Report"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"WCR Report"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule("155") &&
            !DISABLED_MODULE?.includes("Department Analytics") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/deptAnalyticsReport")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Dept Analytics" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Dept Analytics"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Dept Analytics"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule("156") &&
            !DISABLED_MODULE?.includes("Inward Register") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/inwardregister")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Inward Register" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Inward Register"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Inward Register"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule("157") &&
            !DISABLED_MODULE?.includes("Progress report") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/progressreport")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Progress report" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Progress report"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Progress report"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule("158") &&
            !DISABLED_MODULE?.includes("SRF_ULR_Register") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/srfUlrRegisterList")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="SRF_ULR_Register" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"SRF_ULR_Register"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"SRF_ULR_Register"}
                  </Typography>
                )}
              </ListItemButton>
            )}
        </List>
      </Collapse>

      {!isOffLine &&
        hasAccessToModule("14") &&
        !DISABLED_MODULE?.includes("Commercial") && (
          <ListItemButton
            onClick={(e) => setCommercialNested(!CommercialNested)}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Commercial" arrow placement="right">
                <FormatListBulletedIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Commercial"} />
            ) : (
              <ListItemText>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  style={{ fontSize: "14px" }}
                >
                  {"Commercial"}
                </Typography>
              </ListItemText>
            )}
            {CommercialNested ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      <Collapse in={CommercialNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasAccessToModule(111) && !DISABLED_MODULE?.includes("Enquiry") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                clearSearchFilterSessionStorage("/master/enquiry/enquiryList")
              }
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="Enquiry" arrow placement="right">
                  <NewspaperIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"Enquiry"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"Enquiry"}
                </Typography>
              )}
            </ListItemButton>
          )}

          {hasAccessToModule(112) &&
            !DISABLED_MODULE?.includes("Enquiry Followup") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/enquiry/followUpReport")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Enquiry Followup" arrow placement="right">
                    <BookmarkIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Enquiry Followup"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Enquiry Followup"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule(113) &&
            !DISABLED_MODULE?.includes("Price List") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/master/product")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Price List" arrow placement="right">
                    <ArchitectureIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Price List"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Price List"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule(114) &&
            !DISABLED_MODULE?.includes("Quotation") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => clearSearchFilterSessionStorage("/quotation")}
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Quotation" arrow placement="right">
                    <RequestQuoteIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Quotation"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Quotation"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule(115) &&
            !DISABLED_MODULE?.includes("poAcceptance") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => clearSearchFilterSessionStorage("/poAcceptance")}
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="poAcceptance" arrow placement="right">
                    <PodcastsIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"poAcceptance"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"poAcceptance"}
                  </Typography>
                )}
              </ListItemButton>
            )}

          {hasAccessToModule(116) && !DISABLED_MODULE?.includes("Invoice") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => clearSearchFilterSessionStorage("/viewInvoice")}
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="Invoice" arrow placement="right">
                  <ReceiptIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"Invoice"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"Invoice"}
                </Typography>
              )}
            </ListItemButton>
          )}

          {hasAccessToModule(117) &&
            !DISABLED_MODULE?.includes("Supplier Invoice") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  clearSearchFilterSessionStorage("/supplierInvoiceslist")
                }
              >
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <Tooltip title="Supplier Invoice" arrow placement="right">
                    <ReceiptIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Supplier Invoice"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Supplier Invoice"}
                  </Typography>
                )}
              </ListItemButton>
            )}
        </List>
      </Collapse>

      {!isOffLine &&
        hasAccessToModule("17") &&
        !DISABLED_MODULE?.includes("Payment") && (
          <ListItemButton onClick={(e) => setPaymentNested(!paymentNested)}>
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Payment" arrow placement="right">
                <FormatListBulletedIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Payment"} />
            ) : (
              <ListItemText>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  style={{ fontSize: "14px" }}
                >
                  {"Payment"}
                </Typography>
              </ListItemText>
            )}
            {paymentNested ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      <Collapse in={paymentNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!isOffLine && hasAccessToModule("161") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => clearSearchFilterSessionStorage("/paymentMaster")}
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="Payment History" arrow placement="right">
                  <BuildIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"Payment History"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"Payment History"}
                </Typography>
              )}
            </ListItemButton>
          )}
          {!isOffLine && hasAccessToModule("162") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                clearSearchFilterSessionStorage("/accountStatement")
              }
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="Account Statement" arrow placement="right">
                  <PrecisionManufacturingIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"Account Statement"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"Account Statement"}
                </Typography>
              )}
            </ListItemButton>
          )}
          {!isOffLine && hasAccessToModule("163") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => clearSearchFilterSessionStorage("/gstReport")}
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="Gst Report" arrow placement="right">
                  <PrecisionManufacturingIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"Gst Report"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"Gst Report"}
                </Typography>
              )}
            </ListItemButton>
          )}
        </List>
      </Collapse>
      {!isOffLine &&
        hasAccessToModule("21") &&
        !DISABLED_MODULE?.includes("Master In/Out") && (
          <ListItemButton
            // component={Link}
            // to="/masterEQPInOutList"
            onClick={(e) => setMasterInOut(!MasterInOut)}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Payment" arrow placement="right">
                <FormatListBulletedIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Master In/Out"} />
            ) : (
              <ListItemText>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  style={{ fontSize: "14px" }}
                >
                  {"Master In/Out"}
                </Typography>
              </ListItemText>
            )}
            {MasterInOut ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      <Collapse in={MasterInOut} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!isOffLine && hasAccessToModule("171") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                clearSearchFilterSessionStorage("/masterEQPInOutList")
              }
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="In/Out List" arrow placement="right">
                  <ReceiptIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"In/Out List"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"In/Out List"}
                </Typography>
              )}
            </ListItemButton>
          )}
          {!isOffLine && hasAccessToModule("172") && (
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                clearSearchFilterSessionStorage("/inOutReportList")
              }
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <Tooltip title="In/Out Report" arrow placement="right">
                  <BookmarkIcon />
                </Tooltip>
              </ListItemIcon>
              {!isMobile ? (
                <ListItemText primary={"In/Out Report"} />
              ) : (
                <Typography variant="p" component="div" align="left">
                  {"In/Out Report"}
                </Typography>
              )}
            </ListItemButton>
          )}
        </List>
      </Collapse>
      {!isOffLine &&
        hasAccessToModule("28") &&
        !DISABLED_MODULE?.includes("Client Complaints") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/complaintsList")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Client Complaints" arrow placement="right">
                <DomainDisabledIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Client Complaints"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Client Complaints"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {!isOffLine && !DISABLED_MODULE?.includes("Training Resources") && (
        <ListItemButton
          onClick={() => clearSearchFilterSessionStorage("/trainingresources")}
        >
          <ListItemIcon sx={{ minWidth: "45px" }}>
            <Tooltip title="Training Resources" arrow placement="right">
              <ManageAccountsIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Training Resources"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Training Resources"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {!isOffLine &&
        hasAccessToModule("29") &&
        !DISABLED_MODULE?.includes("Client Feedback") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/feedbackList")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Client Feedback" arrow placement="right">
                <FeedbackIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Client Feedback"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Client Feedback"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {!isOffLine &&
        hasAccessToModule("25") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("User") && (
          <ListItemButton
            onClick={() =>
              clearSearchFilterSessionStorage("/master/users/userList")
            }
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="User" arrow placement="right">
                <ManageAccountsIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"User"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"User"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {!isOffLine &&
        hasAccessToModule("27") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Audit") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/audit")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Audit" arrow placement="right">
                <SummarizeIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Audit"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Audit"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {!isOffLine &&
        hasAccessToModule("30") &&
        !DISABLED_MODULE?.includes("Request Form") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/requestFormList")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Request Form" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Request Form"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Request Form"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {!isOffLine &&
        hasAccessToModule("31") &&
        !DISABLED_MODULE?.includes("Document Upload") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/documentUpload")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Document Upload" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Document Upload"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Document Upload"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {!isOffLine &&
        hasAccessToModule("33") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Attendance") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/attendance")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Attendance" arrow placement="right">
                <DocumentScannerIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Attendance"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Attendance"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {!isOffLine &&
        hasAccessToModule("32") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Help") && (
          <ListItemButton
            onClick={() => clearSearchFilterSessionStorage("/help")}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Help" arrow placement="right">
                <LiveHelpIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Help"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Help"}
              </Typography>
            )}
          </ListItemButton>
        )}
      <Collapse in={intermediateProcedure} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() =>
              clearSearchFilterSessionStorage("/master/intermediateProcedure")
            }
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Intermediate Procedure" arrow placement="right">
                <BuildIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Intermediate Procedure" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/master/checkRecords"
            onClick={clearSearchFilterSessionStorage}
          >
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Check Records" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Check Records" />
          </ListItemButton>
        </List>
      </Collapse>
      {isMobile && (
        <>
          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
            }}
          />
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Notification" arrow placement="right">
                <IconButton
                  // onClick={handleClick2}
                  size="small"
                  sx={{ height: "23px", width: "23px" }}
                  aria-controls={open2 ? "Role" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  style={{ backgroundColor: "white", marginRight: "5px" }}
                  disabled
                >
                  <AccountCircleIcon style={{ color: "#1976d2" }} />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
            <Tooltip
              title="User (User Type)"
              style={{ textTransform: "uppercase" }}
            >
              <Typography
                variant="p"
                component="div"
                align="left"
                sx={{ fontSize: "90%" }}
              >
                {storage_userName} (
                {
                  [
                    "admin",
                    "Engineer",
                    "Client",
                    "Branch Admin",
                    "Lab Co-Ordinator",
                    "Account Dept",
                  ][Number(storage_userType) - 1]
                }
                )
              </Typography>
            </Tooltip>
          </ListItemButton>
          {!isOffLine &&
            hasAccessToModule("34") &&
            !DISABLED_MODULE?.includes("MarkAttendance") && (
              <MarkAttendanceModal isMobile={isMobile} />
            )}
          {!DISABLED_MODULE?.includes("HistoricalMode") && (
            <ListItemButton>
              <ListItemIcon
                sx={{
                  minWidth: "45px",
                  backgroundColor: "white",
                  color: "#1976d2",
                }}
              >
                {JSON.parse(sessionStorage.getItem("historicalTable")) ? (
                  <DataUsageIcon />
                ) : (
                  <HistoryIcon />
                )}
              </ListItemIcon>
              <Typography
                variant="p"
                component="div"
                align="left"
                onClick={() => {
                  const currentState = JSON.parse(
                    sessionStorage.getItem("historicalTable")
                  );
                  const newState = !currentState;
                  sessionStorage.setItem(
                    "historicalTable",
                    JSON.stringify(newState)
                  );
                  window.location.reload();
                }}
              ></Typography>
            </ListItemButton>
          )}
          {!DISABLED_MODULE?.includes("OfflineMode") && (
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "45px" }}>
                {!isOffLine ? <WifiOffIcon /> : <WifiIcon />}
              </ListItemIcon>
              <Typography
                variant="p"
                component="div"
                align="left"
                onClick={() => {
                  isOffLine = !isOffLine;
                  localStorage.setItem("isOffLine", isOffLine.toString());
                  window.location.reload();
                }}
              >
                {isOffLine ? "Online" : "Offline"}
              </Typography>
            </ListItemButton>
          )}
          {!isOffLine && <ResetPassword isMobile={isMobile} />}
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="Logout" arrow placement="right">
                <ExitToAppIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography
              variant="p"
              component="div"
              align="left"
              onClick={logout}
            >
              {"Logout"}
            </Typography>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "45px" }}>
              <Tooltip title="info" arrow placement="right">
                <InfoIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography variant="p" component="div" align="left">
              {"LABCITY Software Version 2.0.0"}
            </Typography>
          </ListItemButton>
        </>
      )}
    </List>
  );
  const MobileList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <Typography variant="h6" gutterBottom component="div">
          Calibration
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {sidebarListItems()}
    </Box>
  );

  const handleDrawerToggle = () => {
    if (isMobile) {
      setResposiveAnchor({ ...resposiveAnchor, left: !resposiveAnchor.left });
    } else {
      // handleDrawerOpen();
      setOpen(!open);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          variant="dense"
          style={{ paddingRight: "5px", minHeight: "40px" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            onClick={handleDrawerToggle}
            edge="start"
            // sx={{
            //   marginRight: "5px",
            //   ...(open && { display: "none" }),
            // }}
            sx={{
              marginRight: "5px",
              ...(!isMobile && open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
          <Tooltip title="Refresh">
            <IconButton
              onClick={() => {
                if ("serviceWorker" in navigator) {
                  navigator.serviceWorker.ready.then((registration) => {
                    registration.active.postMessage("clear-cache");
                    window.location.reload(true);
                  });
                }
                window.location.href = `${
                  window.location.pathname
                }?${new Date().getTime()}`;
                window.location.reload(true);
              }}
              size="small"
              sx={{ ml: 2, height: "23px", width: "23px" }}
              style={{ backgroundColor: "white" }}
            >
              <RefreshIcon style={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notification">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, mr: 1, height: "23px", width: "23px" }}
              aria-controls={open2 ? "available-roles" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white" }}
            >
              <Badge color="secondary" badgeContent={`${notificationsNo}`}>
                <NotificationsNoneIcon style={{ color: "#1976d2" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip
            style={{
              textTransform: "uppercase",
              display: "flex",
              gap: "0px",
              alignItems: "center",
            }}
            title="Symbol"
          >
            <IconButton
              size="small"
              sx={{ ml: 2, height: "23px", width: "23px" }}
              style={{ backgroundColor: "white" }}
              onClick={openSymbolModal}
            >
              <TranslateIcon style={{ color: "#1976d2", fontSize: "1.2rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            style={{
              textTransform: "uppercase",
              display: "flex",
              gap: "0px",
              alignItems: "center",
            }}
            title={
              JSON.parse(sessionStorage.getItem("historicalTable"))
                ? `Show Current Data`
                : `Show Historical Data`
            }
          >
            <span>
              <IconButton
                size="small"
                sx={{ ml: 2, height: "23px", width: "23px" }}
                style={{ backgroundColor: "white" }}
                onClick={() => {
                  const currentState = JSON.parse(
                    sessionStorage.getItem("historicalTable")
                  );
                  const newState = !currentState;
                  sessionStorage.setItem(
                    "historicalTable",
                    JSON.stringify(newState)
                  );
                  window.location.reload();
                }}
              >
                {JSON.parse(sessionStorage.getItem("historicalTable")) ? (
                  <DataUsageIcon
                    style={{ color: "#1976d2", fontSize: "1.2rem" }}
                  />
                ) : (
                  <HistoryIcon
                    style={{ color: "#1976d2", fontSize: "1.2rem" }}
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            style={{
              textTransform: "uppercase",
              display: "flex",
              gap: "0px",
              alignItems: "center",
            }}
            title="Online"
          >
            <span>
              <IconButton
                size="small"
                sx={{ ml: 2, height: "23px", width: "23px" }}
                style={{ backgroundColor: "white" }}
                disabled
              >
                {isOffLine ? (
                  <WifiOffIcon
                    style={{ color: "#1976d2", fontSize: "1.2rem" }}
                  />
                ) : (
                  <WifiIcon style={{ color: "#1976d2", fontSize: "1.2rem" }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          {!isMobile && (
            <div
              style={{
                display: "flex",
                marginRight: "20px",
                cursor: "pointer",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Tooltip
                style={{
                  textTransform: "uppercase",
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
                onClick={handleClickDropDown}
              >
                <span>
                  <IconButton
                    // onClick={handleClick2}
                    size="small"
                    sx={{ ml: 2, height: "23px", width: "23px" }}
                    aria-controls={open2 ? "Role" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    style={{ backgroundColor: "white", marginRight: "5px" }}
                    disabled
                  >
                    <AccountCircleIcon style={{ color: "#1976d2" }} />
                  </IconButton>
                  <Typography
                    variant="p"
                    component="div"
                    align="left"
                    sx={{ fontSize: "90%" }}
                    // onClick={handleClickDropDown}
                  >
                    {storage_userName} (
                    {
                      [
                        "admin",
                        "Engineer",
                        "client",
                        "branch admin",
                        "Lab Co-Ordinator",
                        "Account Dept",
                      ][Number(storage_userType) - 1]
                    }
                    )
                  </Typography>
                </span>
              </Tooltip>
              <Tooltip
                title="User (User Type)"
                style={{
                  textTransform: "uppercase",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  sx={{ fontSize: "90%" }}
                  onClick={handleClickDesignation}
                >
                  {selectedDesignation ||
                    (designation.length > 1 &&
                      userDesignation.find(
                        (d) => d.id === parseInt(designation[0])
                      )?.name)}
                </Typography>
              </Tooltip>
              <Tooltip title="Role" style={{ textTransform: "uppercase" }}>
                <span>
                  {props.rolesInfo.roles?.length ? (
                    <Button
                      onClick={handleClick2}
                      size="small"
                      aria-controls={open2 ? "Role" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open2 ? "true" : undefined}
                      style={{
                        color: "white",
                        // width: "193px",
                        justifyContent: "left",
                        textDecorationLine: "underline",
                      }}
                      disabled={
                        !props.rolesInfo.roles ||
                        props.rolesInfo.roles?.length === 1
                      }
                    >
                      ({props.rolesInfo.currentRole?.name || ""})
                    </Button>
                  ) : (
                    ""
                  )}
                </span>
              </Tooltip>
            </div>
          )}
          <Popover
            open={Boolean(dropDown)}
            anchorEl={dropDown}
            onClose={handleCloseDropDown}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {/* <Button onClick={logout}>
                    <LibraryAddCheckIcon style={{ marginRight: "10px" }} />
                    Mark Attendance
                  </Button> */}
                  {!isOffLine &&
                    hasAccessToModule("34") &&
                    !DISABLED_MODULE?.includes("MarkAttendance") && (
                      <MarkAttendanceModal isMobile={isMobile} />
                    )}
                </Grid>
                {!isMobile && !DISABLED_MODULE?.includes("OfflineMode") && (
                  <Grid item>
                    <Button
                      onClick={() => {
                        isOffLine = !isOffLine;
                        localStorage.setItem("isOffLine", isOffLine.toString());
                        window.location.reload();
                      }}
                    >
                      {!isOffLine ? (
                        <WifiOffIcon style={{ marginRight: "10px" }} />
                      ) : (
                        <WifiIcon style={{ marginRight: "10px" }} />
                      )}
                      {isOffLine ? "Online" : "Offline"}
                    </Button>
                  </Grid>
                )}
                {!isMobile && !isOffLine && (
                  <Grid item>
                    <ResetPassword isMobile={isMobile} />
                  </Grid>
                )}
                {!isMobile && (
                  <Grid item>
                    <Button onClick={logout}>
                      <LogoutIcon style={{ marginRight: "10px" }} />
                      Logout
                    </Button>
                  </Grid>
                )}
                {!isMobile && (
                  <Grid item>
                    <Button onClick={handleButtonClick}>
                      <InfoIcon style={{ marginRight: "10px" }} />
                      Info
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </Popover>

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "24px",
                backgroundColor: "#fff",
                position: "fixed",
                top: "7%",
                right: "0px",
                width: "auto",
                textAlign: "right",
                boxSizing: "border-box",
                color: "#fff",
              }}
            >
              <span
                style={{
                  backgroundColor: "#000",
                  padding: "3px",
                }}
              >
                LABCITY Software version 2.0.0
              </span>
            </div>
          </Modal>
        </Toolbar>
      </AppBar>

      {!isMobile ? (
        <Drawer variant="permanent" open={open}>
          {open ? (
            <DrawerHeader sx={{ minHeight: "100px !important" }}>
              <img
                src={`${Logo}`}
                alt="logo"
                style={{
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  height: "100px",
                  width: "70%",
                  margin: "auto",
                  textAlign: "center",
                }}
              />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
          ) : (
            <DrawerHeader sx={{ minHeight: "50px !important" }}></DrawerHeader>
          )}
          {sidebarListItems()}
        </Drawer>
      ) : null}

      <SwipeableDrawer
        anchor={"left"}
        open={resposiveAnchor["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {MobileList("left")}
      </SwipeableDrawer>

      <NotificationList
        anchorEl={anchorEl}
        open={open2}
        close={handleClose}
        notifyNumber={(n) => setNotificationsNo(n)}
        isMobile={isMobile}
      />
      <Menu
        anchorEl={anchorEl2}
        id="available-roles"
        open={open3}
        onClose={handleClose2}
        onClick={handleClose2}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {(props.rolesInfo?.roles || [])
          .filter((e) => (storage_userType == 3 ? e.client : !e.client))
          .map((role) => (
            <MenuItem
              onClick={() => {
                props.rolesInfo.setCurrentRole(role);
              }}
              key={role.id}
            >
              {role.name}
            </MenuItem>
          ))}
      </Menu>

      <Menu
        anchorEl={popoverAnchor}
        open={Boolean(popoverAnchor)}
        onClose={handlePopoverDesignationClosed}
        onClick={handlePopoverDesignationClosed}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {userDesignation
          .filter((d) => designation.includes(String(d.id)))
          .map((el) => (
            <MenuItem
              onClick={() => handleDesignationSelect(el.id)}
              key={el.id}
            >
              {el.name}
            </MenuItem>
          ))}
      </Menu>

      {isSymbolModalOpen && <SymbolCopyModal onClose={closeSymbolModal} />}
    </>
  );
}
