import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Stack,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { fetchAddresses } from "../invoice/productDetails";
import { CSRFFormData } from "./editSrf/csrfForm";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";
import { generatePDF } from "./SRFPrints/print";
import { accuracyFormat, parseRange } from "../../utils/components/accuracyandlcandrangesshow";

let notNeededColumn = ["id","lc","conOfReceipt"];
export default function PrintCSRF() {
  const printComponentRef = React.useRef();
  const params = useParams();
  const [asPerAttach, setAsPerAttach] = React.useState(false);
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  const [contact, setContact] = React.useState(null);
  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [clientID, setClientID] = React.useState("");
  const [billingData, setBillingData] = React.useState({
    billingName: "",
    address: "",
    shippingAddress: "",
  });
  const [biiingAddress, setbiiingAddress] = React.useState("");
  const [companyData, setCompanyData] = React.useState({
    companyName: "",
    address: "",
    gstNumber:"",
  });
  const [address, setAddress] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [clientArray, setClientArray] = React.useState([]);
  const [srfsData, setSRFsData] = React.useState([]);
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [pushArray, setPushArray] = React.useState([]);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [signImg, setSignImg] = React.useState(false);
  const [userId, setUserId] = React.useState({
    createdBy:"",
    approvedBy: "",
    directorBy: "",
  });
  const [userImg, setUserImg] = React.useState({
    createdByImg:"",
    approvedByImg: "",
    directorImg:"",
  });

  const [CSRFformState, setCSRFformState] = React.useState(
    CSRFFormData?.map((item) => ({ answer: "", details: "", ...item }))
  );
  const [quest15, setQuest15] = React.useState({});
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);

  const getCERFForm = async () => {
    await axiosWithToken
      .get(BASE_URL + "csrfForm")
      .then((res) => {
        const data = res.data?.find((item) => item?.srfId == params.id);
        if (data) {
          const updatedCSRFformState = CSRFFormData?.map((item, index) => {
            const fetchedQuestion = data[`quession${index + 1}`];
            const { answer, details } = fetchedQuestion
              ? JSON.parse(fetchedQuestion)
              : { answer: "", details: "" };
            return {
              ...item,
              answer,
              details,
            };
          });
          setCSRFformState(updatedCSRFformState);

          setQuest15(data?.quession15 ? JSON.parse(data?.quession15) : "");
        }
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  function fetchSrfDetails() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfs?_where=(id,eq,${params.id})`)
      .then(async(res) => {
        setServiceReqNumber(res.data[0]?.serviceReqNumber);
        setDcDate(res.data[0]?.dcDate);
        setReceiptDate(res.data[0]?.receiptDate);
        setEntryDate(res.data[0]?.entryDate);
        setCommitedDate(res.data[0]?.committedDeliveryDate);
        setDcNumber(res.data[0]?.dcNumber);
        setClientID(res.data[0]?.clientId);
        setAddress(res.data[0]?.address);
        setSRFsData(res.data[0]);

        const billingResponse = await axiosWithToken.get(
          `${url}clients?_where=(id,eq,${res.data[0].billingId})`
        );
        const billingRes = billingResponse?.data[0];

        if (billingRes) {
          setBillingData({
            billingName: billingRes.companyName,
            address: billingRes.address?.split(",")?.[0],
            shippingAddress: billingRes.shippingAddress?.split(",")?.[0],
          });
        } else {
          console.error("Billing data not found");
        }
        setClientArray(res.data[0]?.contactPersonDetails ? JSON.parse(res.data[0]?.contactPersonDetails) : null);
       
      const createdByUserId = res.data[0]?.createdby;
      const approvedByUserId = res.data[0]?.approvedby;
      const directorByUserId = 121;
      Promise.all([
        createdByUserId
          ? axiosWithToken.get(BASE_URL + `users/${createdByUserId}`)
          : Promise.resolve(null),
        approvedByUserId
          ? axiosWithToken.get(BASE_URL + `users/${approvedByUserId}`)
          : Promise.resolve(null),
        directorByUserId
          ? axiosWithToken.get(BASE_URL + `users/${directorByUserId}`)
          : Promise.resolve(null),
      ])
        .then(([createdByRes, approvedByRes, directorByRes]) => {
          const createdByUserData = createdByRes?.data[0];
          const approvedByUserData = approvedByRes?.data[0];
          const directorByUserData = directorByRes?.data[0];
          const createdByName = createdByUserData?.userName || "";
          const approvedByName = approvedByUserData?.userName || "";
          const directorName = directorByUserData?.userName || "";
          const createdByImg = createdByUserData?.usersign || "";
          const approvedByImg = approvedByUserData?.usersign || "";
          const directorImgByImg = directorByUserData?.usersign || "";
          setUserId((prevState) => ({
            ...prevState,
            createdBy: createdByName,
            approvedBy: approvedByName,
            directorBy: directorName
          }));
          setUserImg((prevState) => ({
            ...prevState,
            createdByImg: createdByImg,
            approvedByImg: approvedByImg,
            directorImg : directorImgByImg
          }));
        })
        .catch((err) => console.error("Error fetching user data:", err));
    })
      
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const getClientId = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients?_where=(id,eq,${clientID})`)
      .then((res) => {
        setCompanyData({
          companyName: res.data[0]?.companyName,
          address: res.data[0]?.address,
          gstNumber: res.data[0]?.gstNumber,
        });

        setCompanyName(res.data[0]?.companyName);
        setContact(
          res.data[0]?.contact ? JSON.parse(res.data[0]?.contact)[0] : null
        );
      })
      .catch((error) => {
        console.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})~and(status,eq,1)`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    // console.log(rows);
  };

  const generatePushArray = (srfInstruments, allInstruments) => {
    const newPushArray = [];

    for (let i = 0; i < srfInstruments.length; i++) {
      newPushArray.push({
        instrumentId: srfInstruments[i].requestedDucName ? srfInstruments[i].requestedDucName : allInstruments.find(
          (element) => element.id === srfInstruments[i].instrumentId
        )?.instrumentName,
        make: srfInstruments[i].make,
        model: srfInstruments[i].model,
        serialNumber: srfInstruments[i].serialNumber,
        DUCID: srfInstruments[i].DUCID,
        ranges: parseRange(srfInstruments[i].ranges),
        calPoint: srfInstruments[i].calPoint,
        accuracy: accuracyFormat(srfInstruments[i].accuracy),
        calFrequency: srfInstruments[i].calFrequency,
        ConOfDuc: srfInstruments[i].ConOfDuc,
        lc: srfInstruments[i].lc,
        conOfReceipt: srfInstruments[i].conOfReceipt,
        location: srfInstruments[i].location == 1 ? "In House" : "Onsite",
        locationOfInstrument: srfInstruments[i].locationOfInstrument,
      });
    }

    return newPushArray;
  };

  useEffect(() => {
    setPushArray(generatePushArray(srfInstruments, allInstruments));
  }, [srfInstruments, allInstruments]);

  useEffect(() => {
    srfInstrumentsList();
    instrumentsList();
    getCERFForm();
    fetchSrfDetails();
  }, []);

  useEffect(() => {
    getClientId();
  }, [clientID]);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    setNewAddressArray(newAddressArray);
  }, [address]);

  useEffect(async () => {
    let newAddressArray = await fetchAddresses(
      billingData?.address,
      billingData?.shippingAddress
    );
    setbiiingAddress(newAddressArray[0]);
  }, [billingData]);

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async () => {
    let footerString = `
    <div id="responsivepdfheader">
        <table style="width:95%;margin-bottom:5px;margin:auto;">
          <tr>
            <td style="border:none;text-align:left">
            <span style="font-size:19px;">
            <span style="text-transform: 'uppercase'">For Customer Representative</span> 
            <br>Name<br>Sign & Date
            </span>
            </td>
            <td style="border:none;text-align:left">
            <span style="font-size:19px;">
            <span style="text-transform: 'uppercase'">Archercal Representative</span> <br>Lab Co-ordinator Name:<br/>Sign & Date
            </span>
            </td>              
          </tr>
          </table>        
    </div>`;

    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = footerString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

//   const generateDynamicFooter = async () => {
//     let htmlString = `
//     <div style="padding:30px;">
//     <table style="width:100%;">
//         <tr>
//         <td style="disply:flex;flex-direction:column;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
//         <div style="border-bottom:1px solid black;padding:2px 15px;">
//         <b style="font-size:18px;">Prepared & Issued By</b>
//         </div>
//          <b style="font-size:17px;padding:0px 15px;">${userId?.createdBy}</b>
//          ${signImg ? 
//           `<div style="padding:5px 15px;height:50px;">
//             ${userImg?.createdByImg ? `<img src="${userImg?.createdByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
//            </div>`
//           : ''}  
//          </div>
//         </td>
//          <td style="disply:flex;flex-direction:column;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
//          <div style="border-bottom:1px solid black;padding:2px 15px;">
//          <b style="font-size:18px;">Approved By</b>
//          </div> 
//           <b style="font-size:17px;padding:0px 15px;">${userId?.approvedBy}</b>
//           ${signImg ? 
//             `<div style="padding:5px 15px;height:50px;">
//               ${userImg?.approvedByImg ? `<img src="${userImg?.approvedByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
//              </div>`
//             : ''}          
//         </td>
//         </tr>
//     </table>
// </div>`;
//     let htmlNode = document.getElementById("header-section");
//     htmlNode.innerHTML = htmlString;
//     let image = await toPng(htmlNode, { quality: 2, scale: 3 });
//     const img = new Image();
//     img.src = image;
//     htmlNode.innerHTML = "";
//     return img;
//   };

  // const generatePDF = async () => {
  //   setDownloadPdfloading(true);
  //   try {
  //     const element = printComponentRef.current;
  //     let pdf = await html2pdf()
  //       .from(element)
  //       .set({
  //         margin: [145, 1, 90, 1],
  //         filename: `srf${params.id}.pdf`,
  //         pagebreak: { after: "section", mode: ["css", "legacy"] },
  //         html2canvas: {
  //           dpi: 192,
  //           scale: 2.5,
  //           letterRendering: true,
  //           useCORS: true,
  //         },
  //         image: { type: 'png', quality: 0.4 },
  //         jsPDF: {
  //           orientation: "portrait",
  //           unit: "pt",
  //           format: "a4",
  //           compress: true,
  //         },
  //       })
  //       .toPdf()
  //       .get("pdf");

  //     const totalPages = pdf.internal.getNumberOfPages();
  //     for (let i = 1; i <= totalPages; i++) {
  //       pdf.setPage(i);
  //       pdf.setFontSize(9);
  //       pdf.setTextColor(0, 0, 0);
  //       pdf.setFont("Courier");

  //       // border add for contnet
  //       const borderWidth = 0.6;
  //       const leftMargin = 15.5;
  //       const borderX = leftMargin;
  //       const borderY = 140;
  //       const rightMargin = 595 - leftMargin * 0.95;
  //       pdf.setLineWidth(borderWidth);
  //       pdf.rect(borderX, borderY, rightMargin - leftMargin, 620);

  //       let image = await generateDynamicHeader(i, totalPages);
  //       pdf.addImage(image, 0, 103, 595, 60);

  //       image = await generateDynamicFooter();
  //       pdf.addImage(image, 0, 755, 595, 60);

  //       if (letterHead) {
  //         let image = await generateLetterHeadHeader();
  //         pdf.addImage(image, 0, 0, 595, 120);

  //         // image = await generateLetterHeadFooter();
  //         // pdf.addImage(image, 0, 810, 595, 30);
  //       }
  //       if (i === totalPages) {
  //         const text =
  //           "This SRF is electronically signed and does not required physical signature";
  //         const textWidth =
  //           (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
  //           pdf.internal.scaleFactor;
  //         const pageWidth = pdf.internal.pageSize.getWidth();
  //         const x = (pageWidth - textWidth) / 2;
  //         {
  //           electronicSign && pdf.text(text, x, 812);
  //         }
  //       }
  //     }

  //     const blob = pdf.output("blob");
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${companyName}_${serviceReqNumber}.pdf`;
  //     a.click();

  //     setDownloadPdfloading(false);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     setDownloadPdfloading(false);
  //   }
  // };


  let letterHeadAndElectronicAndDraft ={
    letterHead,
    asPerAttach,
    signImg,
    electronicSign
  }

  let srfDetails = {
    companyName,
    srfsData,
    newAddressArray,
    clientArray,
    serviceReqNumber,
    receiptDate,
    dcNumber,
    dcDate,
    entryDate,
    commitedDate,
    companyData,
    userId,
    userImg,
    billingData,
    biiingAddress
  }
  let IsCSRF = true;
  let csrfFormProps = {
    CSRFformState,quest15
  }

  let printProps = {
    pushArray,
    letterHeadAndElectronicAndDraft,
    srfDetails,
    setDownlaodPdfLoading,
    IsCSRF,
    csrfFormProps
  }

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="srf-viewsrf_letterhead"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    size="small"
                    id="certificate-certificateviewelectronicsign"
                    options={[
                      { id: 1, label: "With Electronically Signed" },
                      // { id: 3, label: "Signature Image" },
                      { id: 2, label: "Without  Electronically Signed" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Electronically Signed" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "With Electronically Signed" ||
                        newInputValue === ""
                      ) {
                        setElectronicSign(true);
                        setSignImg(false);
                      } else if (
                        newInputValue === "Without  Electronically Signed"
                      ) {
                        setElectronicSign(false);
                        setSignImg(false);
                      }
                      else if (
                        newInputValue === "Signature Image"
                      ) {
                        setElectronicSign(false);
                        setSignImg(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Autocomplete
                    size="small"
                    options={[
                      { id: 1, label: "As per attached list" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="As per attached list" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "As per attached list" ||
                        newInputValue === ""
                      ) {
                        setAsPerAttach(true);
                      }
                    }}
                  />
                </Grid>
        <Grid item  xs={4} sm={3} md={3} lg={2} >
          <Button
            variant="contained"
            size="small"
            sx={{ height:"40px" }}
            disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
            onClick={() => {
              generatePDF(printProps);
            }}
          >
            Download PDF
          </Button>
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
                <ExcelDownload
                  finalData={pushArray}
                  notNeededColumn={notNeededColumn}
                />
      </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef}>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-3"></div>
            <div className="col-6 mt-2">
              <h4 className="font-bold text-center">SERVICE REQUEST FORM</h4>
            </div>
            <div className="col-3 pt-3">
              <span style={{fontSize:"12px"}} className="font-bold text-right">Format No: 7.1/R-03 ,Rev:00 </span>
            </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "13px" }}>
            <table
              style={{ width: "95%", margin: "0px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Client
                </th>
                <td
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {companyName}
                </td>
                <th
                  style={{
                    padding: "5px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  PO No/Quotation Approved Reference
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {" "}
                  {srfsData?.poNumber}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    padding: "5px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  Date
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  {moment(srfsData?.poDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  Address
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {newAddressArray?.[0]?.address}
                </td>
                <th
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  Delivery Challan No & Date
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                  }}
                >{dcNumber}</td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Job No
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Date
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "250px",
                    borderBottom: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  GST No
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {companyData?.gstNumber}
                </td>
                <th
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Customer Name
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {clientArray?.contactPersonName || ""}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Email
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                   {clientArray?.emailId || ""}
                </td>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Phone Number
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "250px",
                    borderBottom: "none",
                  }}
                >
                   {clientArray?.contact || ""}
                </td>
              </tr>

              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Certificate in Name
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {billingData?.billingName}
                </td>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Address
                </th>
                <td style={{ padding: "5px", width: "250px" }}>
                  {biiingAddress?.address}
                </td>
              </tr>
            </table>
            {/* <table
              style={{ width: "95%", margin: "10px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  DC Number
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {dcNumber}
                </td>
                <th
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Date of Receipt
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(receiptDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Entry Date
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Entry Date
                </th>
                <td
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "5px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Calibration to be Completed on
                </th>
                <td style={{ padding: "5px", width: "250px" }}>
                  {moment(commitedDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </table> */}
            <table
              style={{ width: "95%", margin: "5px auto", fontSize: "10px" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={12}
                    style={{ padding: "5px", borderBottom: "none" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th style={{ padding: "7px" }}>Location</th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Declared Accuracy / Acceptance Criteria
                  </th>
                  <th
                    style={{
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray?.length > 0 ? (
                <tbody>
                  {pushArray?.map((item, index) => {
                    return (
                      <tr
                        style={{ lineHeight: "13px" }}
                        key={index}
                        id="pagbreaksborder"
                      >
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.make}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.model}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.serialNumber}
                        </td>
                            <td
                              style={{
                                padding: "5px",
                                borderRight: "none",
                                borderTop: "none",
                              }}
                            >
                              {item?.location}
                            </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.ranges}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.accuracy || "NA"}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calFrequency}
                        </td>
                        <td style={{ padding: "5px", borderTop: "none" }}>
                          {item?.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
            
            {asPerAttach && (
              <table
              style={{ width: "95%", margin: "0px auto", fontSize: "11px" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px" }}
                  >
                    INSTRUMENT DETAILS AS PER ATTACHED LIST
                  </th>
                </tr>
                </thead>
                </table>
            )}
            <Box
              sx={{
                width: "95%",
                margin: "10px auto 0px",
                // border: "1px solid black",
                // padding: "5px",
                fontSize: "13px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <table style={{ border: "none" }}>
                <tbody>
                  {CSRFformState?.map((item, index) => (
                    <tr key={index} style={{pageBreakInside:"avoid"}} id="pagbreaksborder">
                      {!item?.hasPoints && (
                        <td
                          style={{
                            width: "40%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            {index + 1}.{item?.question}
                          </Typography>
                        </td>
                      )}

                      {item?.hasPoints && (
                        <td
                          style={{
                            width: "40%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            <b>
                              {index + 1}.{item?.question}
                            </b>
                          </Typography>
                          {item?.points?.map((option, index) => (
                            <Typography
                              variant="caption"
                              display="block"
                              key={index}
                            >
                              {option}
                            </Typography>
                          ))}
                        </td>
                      )}
                      <td style={{ border: "1px solid black" ,borderBottom:"none", width:"60%" , verticalAlign:"top",padding:"0px 5px"}}>
                        {item?.options?.map((option, optionIndex) => (
                          <span key={optionIndex}>
                            <Checkbox
                              size="small"
                              sx={{ m: "0px", p: "0px 5px" }}
                              checked={item?.answer === option}
                            />
                            {option}
                          </span>
                        ))}
                      {item?.hasDetails && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft:"5px"
                          }}
                        >
                          <Typography variant="span">
                            {item?.details}
                          </Typography>
                        </div>
                      )}
                      {item?.hasoptions && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.options1.map((option, optionIndex) => (
                            <span key={optionIndex}>
                              <Checkbox
                                size="small"
                                sx={{ m: "0px", p: "0px 5px" }}
                                checked={item?.details === option}
                              />
                              {option}
                            </span>
                          ))}
                        </div>
                      )}
                      {item?.hasPoints && (
                        <div
                          style={{
                            border: "none",
                            // display: "flex",
                            // alignItems: "center",
                          }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: "100%" }}
                          >
                            <Typography variant="span">
                              Certificate to be issued in the name of:                            
                              {quest15?.details?.split(",")[1]?.trim()}
                            </Typography>
                          </FormControl>
                        </div>
                      )}
                      </td>

                    </tr>
                  ))}
                   <tr id="pagbreaksborder">
                    <td colSpan={2} style={{ border:"1px solid black",borderRight:"none",borderBottom:"none" }}>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                  margin: "10px auto",
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                }}
              >
                <div>
                  <span>
                    Note : If Conformity Statement requested by customer then
                    the specification or standard applied for conformity
                    statement and the decision rule shall be reported on
                    calibration certificate. The decision rule shall be applied
                    as below.
                    <br />
                    PASS – Error ± expanded uncertainty within limits /
                    specifications
                    <br />
                    PASS# - Error are within limits/Specifications but overlap
                    when expanded uncertainty is taken into account.
                    <br />
                    FAIL# - Error exceeds limits/specifications but overlap when
                    expanded uncertainty is taken into account.
                    <br />
                    FAIL – Error ± expanded uncertainty exceeds limits /
                    specifications.
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "10px auto 0px",
                  pageBreakBefore: "always",
                }}
              >
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <h6>*********For Office Use Only*********</h6>
                </div>

                <div>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "11px"
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        a) Feasibility of Calibration :{" "}
                        <input type="checkbox" /> Yes <input type="checkbox" />
                        No
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        b) Functionality of Calibration :
                        <input type="checkbox" /> Ok <input type="checkbox" />{" "}
                        Not Ok
                      </div>
                    </div>
                    <div>
                      Service Request Review and Approved by
                      <br />
                      <br />
                      Technical Manager
                      <br />
                      Name:
                      <br />
                      Sign & Date
                    </div>
                  </div>
                  <h6 style={{ textAlign: "left" , fontSize: "10.5px", marginTop:"-10px"}}>
                    Review of Record of Deviation from Contract
                  </h6>
                  <table
                    className="standardUnitTable single-border-table"
                    align="center"
                    style={{fontSize: "10.5px"}}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th>Sr. No.</th>
                      <th> Work Order No.</th>
                      <th> Details of Deviation from Contract </th>
                      <th>Revision Made </th>
                      <th>Confirmation from customer</th>
                    </tr>
                    <tr style={{ textAlign: "center" }}>
                      <th>{"\u00A0"}</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </table>
                </div>
                <div
                  style={{
                    textAlign: "end",
                    display: "flex",
                    justifyContent: "end",
                    width: "70%",
                    margin: "auto",
                    fontSize: "11px"
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <b>Reviewed and Approved by</b>
                    <br />
                    <b>_____________________________</b>
                    <br />
                    <span>Technical Manager</span>
                    <br />
                    <span>Name:</span>
                    <br />
                    <span>Date</span>
                  </div>
                </div>
                <div style={{fontSize: "11px"}}>
                  <h6 style={{ textAlign: "center" }}>TERMS & CONDITIONS</h6>
                  <h6 style={{ textAlign: "center" }}>
                    <u style={{ marginBottom: "0.5em" }}>
                      Subject to change / modification without prior notice
                    </u>
                  </h6>
                  <div style={{ textAlign: "left", fontSize: "9.5px" }}>
                    <span>
                      1. ACPL follows only National / International calibration
                      methods. ACPL assumes that the calibration methods used by
                      the Laboratory and our best measuring capabilities are
                      acceptable to the customer.
                      <br />
                      2.Charges are payable 100 % against delivery through cash
                      / cheque in favour of ARCHERCAL PRIVATE LIMITED, MUMBAI.
                      <br />
                      3.Equipments and their accessories delivered to ACPL shall
                      be in working condition.Damage if any, shall be recorded
                      in the SRF.
                      <br />
                      4.ACPL personnel shall take every possible care in
                      handling and / or use of customer’s equipment. ACPL
                      however, shall not be liable for any financial damage and
                      / or legally bound for any damage to customer’s equipment
                      during job execution, and which are attributed to the
                      properties specifications of equipment.Liability of ACPL
                      shall be restricted to repair of equipment in the event of
                      damage during period of custody with ACPL.Delivery /
                      collection
                      <br />
                      through courier shall be the responsibility of customer.
                      <br />
                      5. Equipment submitted by customer may be returned to
                      customer without executing the job under circumstances or
                      exigencies beyond the control of ACPL. Customer will be
                      briefed by ACPL personnel in such events and provided with
                      appropriate advice.
                      <br /> 6. The customer shall satisfy themselves at the
                      time of accepting delivery of the equipments and reports.
                      <br /> 7. Customer has to make own arrangements for taking
                      delivery of their equipments.Report would be dispatched by
                      courier whenever requested by customer.
                      <br /> 8. As a policy, electronic transmission of results
                      is not permitted. In special case of urgency, the customer
                      is requested to submit such written request to ACPL. While
                      receiving and approving such request, ACPL shall assume
                      that the customer has assumed total responsibility and
                      risk involved for any deviation in the end result.
                      <br /> 9. Calibration report issued by ACPL is not to be
                      used for any legal purpose and shall not be produced in
                      court of law.For any disputes, decision of Directors of
                      ACPL will be final.
                      <br /> 10. Customer shall collect equipments within 15
                      days of job execution. If equipments are not collected
                      within reasonable time and / or without satisfactory
                      justification, additional service charge may be levied.
                      Decision of Directors of ACPL shall be final.
                      <br /> 11. Customer shall recognize that a satisfactory
                      calibration report in no way implies that the equipment
                      calibrated is approved by ACPL.No part of calibration
                      report shall be used by customer for promotional or
                      publicity purpose in any way that ACPL may consider
                      misleading.
                      <br /> 12. As a customer friendly practice, a PDCR
                      (probable date of job completion with report) is mentioned
                      in the SRF.Customer may therefore enquire about the job
                      completion only on / after the PDCR quoting SRF number.
                      <br /> 13. ACPL shall make delivery of equipment and
                      related report to customer representative only on
                      production of customer copy of SRF, in absence of which
                      ACPL personnel may refuse to hand over the same.
                      <br /> 14. Equipments submitted for calibration must be
                      accompanied by the following:
                      <ul>
                        <li style={{ listStyleType: "disc" }}>
                          Details of the Equipments: Model & Serial Number
                          (accessories to be specified separately)
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Operating manual
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Previous Calibration Report (wherever possible)
                        </li>
                      </ul>
                      15.In absence of specific instructions from customer, all
                      calibrations shall be performed in accordance with ACPL
                      Quality System Procedure.
                      <br /> 16. ARCHERCAL PRIVATE LIMITED in neither allowed to
                      make misleading/ unauthorised statement regarding its
                      accreditation nor refer to accreditation in such a way so
                      as to imply that a product calibrated by ACPL, it's
                      process, service, management system or person is approved
                      by NABL.
                    </span>
                    <div
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      <span>
                        Kindly note that Calibration charges are payable even if
                        the instrument’s performance is observed to be beyond
                        its declared specifications.
                        <br />
                        Archercal Private Limited, MUMBAI.
                        <br />
                        WORKING DAYS: MONDAY - SATURDAY BUSINESS HOURS: 8.00 AM
                        – 6:30 PM.
                        <br />
                        CONTACT DETAILS: +912249795948,+919833049675
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      <ToastContainer />
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"SRF"} />
      )}
    </Paper>
  );
}
