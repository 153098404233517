import autoTable from "jspdf-autotable";
import { customFontFamily, footerConfig, lastpagesection, tableConfiguration, totalHeaderHeightWithCalibrationLableorNot } from "./srfsConfig";
import { layoutConfig, printSRFSConfigData, printSRFSConfiguration } from "./SRFSConstant";
import { addNewPage } from "./print";
import { DateisValid } from "../../../utils/components/accuracyandlcandrangesshow";

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printSRFSConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printSRFSConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth) / 2;
  } else if (align === "right") {
    xPos =
      printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth - xSize;
  }

  printSRFSConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawBox = async (panels) => {
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 30, 25, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize:
        panels.tableTextfontSize || printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      valign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;

  let row = [
    {
      content: panels?.leftPanel.keys[0],
      rowSpan: rowSpan,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[0],
      rowSpan: rowSpan,
      styles: { valign: "middle", halign: "left" },
    },
    {
      content: panels?.rightPanel.keys[0],
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.rightPanel.values[0],
      styles: { valign: "middle", halign: "left" },
    },
  ];
  tableRows.push(row);

  for (let i = 1; i < panels?.rightPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.rightPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.rightPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Add the final row with Address (colSpan 3)
  // let addressRow = [
  //   {
  //     content: panels?.leftPanel.keys[1], // "Client" key, to keep the layout
  //     styles: {
  //       font: customFontFamily?.boldFont,
  //       fontStyle: customFontFamily?.bold,
  //       valign: "middle",
  //       halign: "left",
  //     },
  //   },
  //   {
  //     content: panels?.leftPanel.values[1], // Address content
  //     colSpan: 3, // This will span across the last 3 columns
  //     styles: { valign: "middle", halign: "left" },
  //   },
  // ];

  // tableRows.push(addressRow);

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
   const columnPercentages = [20, 30, 25, 25];

  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Render the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      halign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 0;
};


export const notesTable = async (remarksText,setPageSection) => {
  // Prepare the section before drawing
  await setPageSection();
  layoutConfig.yPositionGlobal += 10;

  // Get the document and page dimensions
  const doc = printSRFSConfiguration.pdfDoc;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  
  const remarksWidth = (pageWidth * 91) / 100;
  
  // Set the font size and line spacing factor
  const fontSize = printSRFSConfiguration.fontSizes.h2;
  const lineSpacingFactor = 1.2;
  doc.setFontSize(fontSize);


  // Calculate the total height needed for all remarks after wrapping
  let totalRemarksHeight = 0;
  // This array will store the wrapped lines for each remark
  const wrappedLinesPerRemark = [];

  remarksText.forEach((line) => {
    // Clean up the line and split it to the specified width
    const cleanLine = line.trim().replace(/\t/g, " ");
    const wrappedText = doc.splitTextToSize(cleanLine, remarksWidth);
    wrappedLinesPerRemark.push(wrappedText);
    totalRemarksHeight += wrappedText.length * fontSize * lineSpacingFactor;
  });

  // Check if there is enough space on the current page; if not, add a new page
  if (
    layoutConfig.yPositionGlobal + totalRemarksHeight >
    pageHeight -
      footerConfig.footerimgHeight -
      footerConfig.footertableHeight -
      footerConfig.footerandbodygap
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal = totalHeaderHeightWithCalibrationLableorNot.total + 5;
  }

  // Draw the wrapped text lines using the PDF doc's text method
  const xPositionRemarks = layoutConfig.xPositionGlobal;
  wrappedLinesPerRemark.forEach((wrappedText) => {
    wrappedText.forEach((wrappedLine) => {
      doc.text(wrappedLine, xPositionRemarks, layoutConfig.yPositionGlobal, {
        maxWidth: remarksWidth,
        align: "left",
      });
      // Increment yPositionGlobal by the calculated line height
      layoutConfig.yPositionGlobal += fontSize * lineSpacingFactor;
    });
  });

  // Final vertical adjustment after drawing the remarks
  layoutConfig.yPositionGlobal += 5;
};

export const lastPageSectionTable = async (setPageSection) => {
  await setPageSection(true);
  let srfsSetting =
    printSRFSConfigData.letterHeadAndElectronicAndDraftConfig;

  let Directorimg = printSRFSConfigData.SRFSDetailsConfig.userImg?.directorImg || "";
  let directName = printSRFSConfigData.SRFSDetailsConfig.userId?.directorBy || "";
  drawText(
    "*********For Office Use Only*********",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "center",
    printSRFSConfiguration.fontSizes.h1,
    5,
    5
  );
  drawText(
    "a) Feasibility of Calibration : ☐ Yes ☐ No",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "left",
    printSRFSConfiguration.fontSizes.h4,
    2,
    5,
    "normalfont"
  );
  drawText(
    "Service Request Review and Approved by",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal - 20,
    "right",
    printSRFSConfiguration.fontSizes.h4,
    2,
    5,
    "normalfont"
  );
  drawText(
    "b) Functionality of Calibration :☐ Ok ☐ Not Ok",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "left",
    printSRFSConfiguration.fontSizes.h4,
    2,
    5,
    "normalfont"
  );
  layoutConfig.yPositionGlobal -=15;
  // Define an array of text items with an optional font parameter
  const textItemsfirst = [
    { text: "Director-Technical\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", },
    ...(srfsSetting?.electronicSign ? [{
       text: "Electronically Signed By\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", font: "normalfont" 
    }] : []),
    {
      text: `Name: ${srfsSetting?.electronicSign ? directName : "____________"}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`,
      font: "normalfont"
    },    
    { text: `Date: ${srfsSetting?.electronicSign ? DateisValid(printSRFSConfigData.SRFSDetailsConfig?.entryDate) : "__________"}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`, font: "normalfont" },
  ];
  
  // Loop through each text item and call drawText
  textItemsfirst.forEach((item) => {
    drawText(
      item.text,
      layoutConfig.xPositionGlobal,
      layoutConfig.yPositionGlobal,
      "right",
      printSRFSConfiguration.fontSizes.h4,
      2,
      2,
      item.font
    );
  });

  if(srfsSetting?.electronicSign && Directorimg){
    printSRFSConfiguration.pdfDoc.addImage(
      Directorimg,
      "PNG",
      printSRFSConfiguration.pageWidth - 75,
      layoutConfig.yPositionGlobal - 25,
      50,
      25
    );
    layoutConfig.yPositionGlobal +=5;
  }

  drawText(
    "Review of Record of Deviation from Contract",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal - 10,
    "left",
    printSRFSConfiguration.fontSizes.h4,
    2,
    5,
    "normalfont"
  );
  let contractRows = [];
  // Add the header row (each cell is an object with custom styles)
  contractRows.push([
    {
      content: "Sr. No.",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
        valign: "middle",
      },
    },
    {
      content: "Work Order No.",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
        valign: "middle",
      },
    },
    {
      content: "Details of Deviation from Contract",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
        valign: "middle",
      },
    },
    {
      content: "Revision Made",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
        valign: "middle",
      },
    },
    {
      content: "Confirmation from customer",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "left",
        valign: "middle",
      },
    },
  ]);

  // Add one empty row (with 5 empty cells)
  contractRows.push([
    { content: "" },
    { content: "" },
    { content: "" },
    { content: "" },
    { content: "" },
  ]);

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - 10,
    margin: tableConfiguration?.margins,
    body: contractRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h4,
      halign: "center",
      font: customFontFamily?.normalFont,
      cellPadding: { top: 1, left: 5, bottom: 1.5, right: 5 },
    },
  });

  // Update y position after the table
  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;

  // Define an array of text items with an optional font parameter
  const textItems = [
    { text: "Reviewed and Approved by\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" },
    { text: "Director-Technical\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", },
    ...(srfsSetting?.electronicSign ? [{
     text: "Electronically Signed By\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", font: "normalfont" 
    }] : []),
    {
      text: `Name: ${srfsSetting?.electronicSign ? directName : "____________"}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`,
      font: "normalfont"
    },
    { text: `Date: ${srfsSetting?.electronicSign ? DateisValid(printSRFSConfigData.SRFSDetailsConfig?.entryDate) : "__________"}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`, font: "normalfont" },
  ];

  // Loop through each text item and call drawText
  textItems.forEach((item) => {
    drawText(
      item.text,
      layoutConfig.xPositionGlobal,
      layoutConfig.yPositionGlobal,
      "right",
      printSRFSConfiguration.fontSizes.h4,
      2,
      2,
      item.font
    );
  });

  if(srfsSetting?.electronicSign && Directorimg){
    printSRFSConfiguration.pdfDoc.addImage(
      Directorimg,
      "PNG",
      printSRFSConfiguration.pageWidth - 75,
      layoutConfig.yPositionGlobal - 25,
      50,
      25
    );
    layoutConfig.yPositionGlobal +=5;
  }

  let scaledHeight = printSRFSConfiguration.pageWidth / 2;
  // Iterate through the certificateEndSection array
  for (const section of lastpagesection) {
    if (section?.type === "header") {
      let updateX =
        section?.align == "left" ? layoutConfig.xPositionGlobal : scaledHeight;
      drawText(
        section?.content,
        updateX,
        layoutConfig.yPositionGlobal,
        section?.align,
        printSRFSConfiguration.fontSizes.h2,
        3,
        3
      );
    } else if (section?.type === "table") {
      const content = section.content;
      const tableData = [];
      content.forEach((item, index) => {
        // Only add the first column (index) if 'index' is true
        if (section.index) {
          tableData.push([`${index + 1}.`, item]);
        } else {
          tableData.push([item]); // Only push the content (no index column)
        }
      });
      // Render the table
      autoTable(printSRFSConfiguration.pdfDoc, {
        startY: layoutConfig.yPositionGlobal - 10,
        margin: tableConfiguration?.margins,
        body: tableData,
        theme: "grid",
        styles: {
          fillColor: false,
          textColor: printSRFSConfiguration.textColor,
          lineColor: printSRFSConfiguration.textColor,
          lineWidth: printSRFSConfiguration.lineWidth,
          fontSize: printSRFSConfiguration.fontSizes.h4,
          halign: "justify",
          lineWidth: 0, // Remove border
          lineColor: false, // Remove border color
          font: customFontFamily?.normalFont,
          cellPadding: { top: 1, left: 5, bottom: 1.5, right: 5 },
        },
      });

      // Update y position after the table
      layoutConfig.yPositionGlobal =
        printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
    }
  }

  const tableData = [
    [
      "Kindly note that Calibration charges are payable even if the instrument’s performance is observed to be beyond its declared specifications.",
    ],
    [
      "Archercal Private Limited, Mumbai.",
    ],
    ["Worjing Days: Monday - Saturday Business Hours: 8.00 AM – 6:30 PM."],
    ["Contact Details: +912249795948, +919833049675"],
  ];
  // Render the table
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - 10,
    margin: tableConfiguration?.margins,
    body: tableData,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h4,
      halign: "center",
      lineWidth: 0, // Remove border
      lineColor: false, // Remove border color
      font: customFontFamily?.normalFont,
      cellPadding: { top: 1, left: 5, bottom: 1.5, right: 5 },
    },
    tableLineColor: printSRFSConfiguration.textColor,
    tableLineWidth: printSRFSConfiguration.lineWidth,
  });

  // Update y position after the table
  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};
